import React, { useState, useEffect, useRef, createRef } from "react";
import axios from "axios";
import io from "socket.io-client";
import { useSpring, animated } from "@react-spring/web";
import { useDrag } from "react-use-gesture";
import _ from "lodash";
import { FaWindowClose } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { BiMessageSquareAdd } from "react-icons/bi";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { BsEmojiDizzy } from "react-icons/bs";
// TODO:  I CAN START TIMER LOCALLY BY SENDING  START FROM BACKEND

// import * as Scroll from 'react-scroll';
// import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const socket = io(`${process.env.REACT_APP_GOIN_SERVER}`, {
  transports: ["websocket", "pooling"]
});

// const socket = io("http://localhost:9000", {
//   transports: ["websocket", "pooling"],
// });

function App() {
  const [phone, setPhone] = useState("");
  const [userName, setUserName] = useState("");
  const [user, setUser] = useState({});
  const [topic, setTopic] = useState("");
  const [createdTopic, setCreatedTopic] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [posts, setPosts] = useState([]);
  const [body, setBody] = useState("");
  const [prevMessage, setPreviousMessage] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [prevSendMessage, setPreviousSendMessage] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [invalidLoginError, setInvalidLoginError] = useState("");
  const [thankYou, setThankYou] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState();
  const [showTopicModal, setShowTopicModal] = useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const textInput = useRef(null);
  const elementRef = useRef();

  // const handleClick = () =>
  // ref.current.scrollIntoView({
  //   behavior: 'smooth',
  //   block: 'start',
  // });

  const url = process.env.REACT_APP_GOIN_SERVER
  // const url = process.env.REACT_APP_GOIN_TEST_SERVER;

  useEffect(() => {
    userEntry();
  }, []);

  useEffect(() => {
    socket.on("incoming-message", (msg) => {
      setPosts(msg);
    });
    return () => {
      socket.off("incoming-message");
    };
  }, [socket]);

  useEffect(() => {
    socket.on("current-topic", (topic) => {
      setTopic(topic);
    });
    return () => {
      socket.off("current-topic");
    };
  }, [socket]);

  useEffect(() => {
    socket.on("connect", () => {
      // console.log("you connected w " + socket.id);
    });
  }, [socket, posts]);

  useEffect(() => {
    const socketTimer = () => {
      socket.on("time-remaining", (time) => {
        // console.log("🚀 ~ file: App.js ~ line 84 ~ socketMessages ~ time", time)
        return setTimeRemaining(time);
      });
    };
    socketTimer();
    return () => {
      socket.off("time-remaining");
    };
  }, [socket]);

  useEffect(() => {
    const getAllPost = () => {
      axios.get(`${url}posts`).then((response) => {
        // console.log("set post - run get all post" +  JSON.stringify(response.data, null, 2));
        const x = response.data.posts;
        // console.log("HAPPENING ONE");
        setPosts(x.reverse());
      });
    };
    getAllPost();
  }, []);

  useEffect(() => {
    const getTopic = () => {
      axios.get(`${url}topics`).then((response) => {
        setTopic(response.data);
      });
    };
    getTopic();
  }, []);

  const validatePhone = (phoneNumber, un) => {
    // console.log("🚀 ~ file: App.js ~ line 134 ~ validatePhone ~ userName", (!!un == false) )
 
    var phoneRe =
      /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
    var digits = phoneNumber.replace(/\D/g, "");
    const isvalid = phoneRe.test(digits);
    if (!isvalid) {
      setPhone("");
      return setPhoneError("please add valid phone number");
    }

    // if (!!un == false) {
    //   setUserName('a·non·y·mous');
    // }
    return isvalid;
  };

    

  const login = async  (event) => {
    event.preventDefault();
    const x =  validatePhone(phone, userName);

    if (x) {
      await axios
        .post(`${url}onboard`, {
          phone,
          user_name: userName.trim(),
        })
        .then((Response) => {
          console.log("🚀 ~ file: App.js ~ line 149 ~ .then ~ Response", JSON.stringify(Response.data.error,null, 2))
          if ( Response.data.error ) {
            setPhone("");
            setUserName("");
            setInvalidLoginError(Response.data.error)
            return 
          }
          setPhone("");
          setUserName("");
          setLoggedIn(true);
          setUser(Response.data.user);
          localStorage.setItem("token", Response.data.token);
        }).catch((error) =>{
        console.log("🚀 ~ file: App.js ~ line 156 ~ .then ~ error", error)
          
        } );
    }
  };
  const logout = () => {
    localStorage.removeItem("token");
    setLoggedIn(false);
    setUser(null);
  };

  const userEntry = () => {
    const token = localStorage.getItem("token");
    try {
      axios.get(`${url}user/checkForUser/${token}`).then((response) => {

        // console.log("🚀 ~ file: App.js ~ line 31 ~ axios.get ~ response", response);
        if (response.data) {
          setUser(response.data);
          setLoggedIn(true);
        }
      });
    } catch (err) {
      console.log("error: " + err);
    }
  };

  const sendCreatedTopicToDB = (e) => {
    e.preventDefault();
    if (createdTopic !== "") {
      setThankYou(true);
      axios
        .post(`${url}topics/${user._id}`, {
          topic: createdTopic,
        })
        .then((response) => {
          setCreatedTopic("");
          setTimeout(() => {
            setThankYou(false);
            toggleCreateTopicModal();
          }, 4000);
        })
        .catch((error) => console.log("error: " + error));
    }
  };

  const createPost = (e) => {
    e.preventDefault();
    // console.log("creating post", body);
    // const x =
    if (body !== "") {
      // socket.emit("custom-event", {  body: `${prevSendMessage}. ${body}`, user_name: user.user_name,  topicID: topic._id });
      axios
        .post(`${url}post/new/${user._id}`, {
          body: `${prevSendMessage}💭 -  ${body}`,
          topicID: topic._id,
          postedTime: Date.now(),
        })
        .then((response) => {
          setBody("");
          setPreviousMessage("");
          setPreviousSendMessage("");
          // setPosts(x.reverse());
        })
        .catch((error) => console.log("error: " + error));
    }
  };

  const toggleCreateTopicModal = () => {
    setShowTopicModal(!showTopicModal);
  };

  const getValueFromLastMessage = (body) => {
    const x = body.match(/.{6}$/);
    const y = body.match(/.{10}$/);
    setPreviousSendMessage(`...${y[0]}`);
    setPreviousMessage(`...${x[0]}`);
    textInput.current.focus();
  };

  return (
    <>
    <div style={{overflow: 'hidden', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0}}>
    <div  className="max-h-screen flex items-start justify-center">
      {/* {showTopicModal ? ( */}
      {showTopicModal ? (
        <div style={{maxWidth: '768px'}} className="z-20 absolute h-screen  w-screen flex items-center justify-center bg-opacity-80 bg-black">
          <div className="border-2 border-black rounded-md w-80 h-80 bg-lightGray flex flex-col items-start justify-start">
          <div className="px-4 rounded-t-sm bg-black w-full flex justify-between items-center border">
              <p className="px-4 text-white">Submit Randomized Topic </p>
              <FaWindowClose
                style={{ right: 14 }}
                onClick={() => toggleCreateTopicModal()}
                className=" text-white  rounded-full"
              />
            </div>
            <div className="h-1/2 flex items-center justify-center w-full">
              <img className="w-full h-40" src="https://media4.giphy.com/media/Nw8z2olm0nGHC/200.gif" alt="" />
            </div>
            <div className="w-full pt-2 pl-8">
              <ul className=" list-disc text-gray text-xs">
                <li>submit a topic: 24 Characters </li>
                <li> watch them get weird </li>
                <li> mind your buisness... or not </li>
                <li> repeat </li>
              </ul>
            </div>
           
            {!thankYou ? (
              <>
                <input
                  className="px-2 border-2 border-pd-gray  w-full"
                  name="topic"
                  placeholder="enter a topic"
                  onChange={(event) => setCreatedTopic(event.target.value)}
                  value={createdTopic}
                  maxLength="24"
                  type="text"
                />
                <button
                  onClick={(e) => sendCreatedTopicToDB(e)}
                  className="w-full text-white font-semibold bg-green"
                >
                  {" "}
                  submit{" "}
                </button>
              </>
            ) : (
              <div className="w-full h-full bg-red flex items-center justify-center">
                <p className="text-white"> Topic Submitted </p>
              </div>
            )}
          </div>
        </div>
      ) : null}

      <div style={{maxWidth: '768px'}} className=" flex  w-screen h-screen  flex flex-col items-center justify-start bg-white bg-opacity-80">
        <div className=" absolute pt-4 w-full  pt-1 px-8 flex flex-col items-center justify-center border-b border-lightGray ">
          <h1 className="text-red text-2xl text-center w-full tracking-widest">
            {" "}
            <span style={{ marginRight: '-8px'}} className={loggedIn ? 'text-gray' : 'text-red'}> Keep </span><span className="text-gray ">It</span><span style={{ marginLeft: '-8px'}} className={loggedIn ? 'text-green animate-pulse' : 'text-red'}> Go'</span> 
            <span className="text-gray">N</span>{" "}
          </h1>
          <p className="text-xs mb-4">feel free to go in 💭</p>
          {user?._id?.length >= 1 ? (
            <>
              <div className="w-full flex flex-row justify-between mb-1">
                <p className="w-1/2 text-xs"> {user?.user_name} </p>
                <button
                  className="border border-gray rounded-md w-1/4 bg-white text-center text-gray text-xs rounded-full"
                  onClick={logout}
                >
                  Log Out
                </button>
              </div>
            </>
          ) : (
            <div style={{maxWidth: '768px'}} className="flex flex-col pb-4 bg-white bg-opacity-80 w-full md:w-80">
              <input
                className={
                  !phoneError
                    ? "px-4 mt-1 border-2 border-pd-gray rounded-md"
                    : "px-4 mt-1 border-2 border-red rounded-md"
                }
                name="phone"
                placeholder={!phoneError ? "phone" : phoneError}
                onChange={(event) => setPhone(event.target.value)}
                value={phone}
                maxLength="20"
                type='number'
                 inputMode='numeric'
                  pattern="[0-9]*"
              />

              <input
                className="px-4 mt-1 border-2 border-pd-gray rounded-md"
                name="user_name"
                placeholder={"select user name"}
                onChange={(event) => setUserName(event.target.value)}
                value={userName}
                maxLength="20"
                type="text"
              />
             { invalidLoginError !== "" ? <p className=" w-full text-center text-xs text-red">
                {invalidLoginError}
              </p> : null}
              <button
                className={(phone !== "") && (userName !== "") ? "mt-2 bg-green rounded-full text-white font-bold": "mt-2 bg-gray rounded-full text-white font-bold"}
                disabled={(phone == "") || (userName == "")}
                onClick={login}
              >
                submit
              </button>
            </div>
          )}

          <div className="w-full flex flex-col bg-opacity-80 bg-white">
            {/* <div className=" w-full border-2 h-2 bg-red border-red"></div> */}
            <div className="py-4  flex justify-between items-center">
              {!!topic.topic !== "" ? (
                <h1 className="font-bold tracking-wider w-full text-center">
                  {topic.topic}: {(timeRemaining == '-1s ') || (timeRemaining == null) ? <span className="text-green text-xs"> GO IN! </span> :  timeRemaining}
                </h1>
              ) : null}
              <button
                onClick={() => toggleCreateTopicModal()}
                className="hover:bg-grey p-2 rounded-sm"
              >
                {!showTopicModal ? (
                  <BiMessageSquareAdd className="animate-pulse fill-current text-green" />
                ) : (
                  <FaWindowClose />
                )}
              </button>
            </div>
          </div>
        </div>

        {/* messaging modal */}
        <div className="w-full flex justify-center items-start mt-28">
          <div className="w-full p-8">

            {posts.length >= 1 && (
              // <div className="bg-opacity-80 bg-black"></div>
              <div
                style={{ marginTop: 0, maxHeight: 350 }}
                className="bg-offWhite px-1 overflow-scroll z-100 flex flex-col-reverse mt-5 gap-6 text-sm h-get-it-goin-message-container"
              >
                {posts.map((post, index) => {
                  return (
                    <div key={index} className="w-full">
                      {/* <BsEmojiDizzy style={{width: 30}} className="mr-4 border"/> */}
                      <div className="border bg-light-Gray border-pd-gray rounded-md flex justify-between items-center p-2 rounded-sm shadow-sm">
                        <div className="w-full flex justify-start items-center gap-2">
                          {/* <BsEmojiDizzy style={{width: 30}} className="mr-4 border"/> */}
                          <div style={{ width: "100%" }} className="flex flex-col">
                            {/* user info goes here */}
                            <p className="items-center mb-1 text-gray font-bold tracking-wider w-full flex justify-between">
                              {post.postedBy?.user_name}  <span className={post.topicID?.topic == topic.topic ? "text-green text-xs pr-2 text-right" : "text-red text-xs pr-2 text-right"}> {post.topicID?.topic} </span> 
                            </p>
                            <p className="text-gray text-xs" key={post._id}>
                              {" "}
                              {post.body}
                            </p>

                            {/* <p className="text-gray text-xs">  </p> */}
                          </div>
                        </div>
                        {true ? <AiOutlineHeart /> : <AiFillHeart />}
                      </div>
                      <div className="w-full flex items-center justify-between">
                        {/* {JSON.stringify(post)} */}
                        <div className="pl-4 text-pd-gray">{`${new Date(post.created).toLocaleDateString("en-US")} ${new Date(post.created).toLocaleTimeString("en-US")}`}</div>
                        <button
                        disabled={post.topicID?.topic !== topic.topic}
                          onClick={() => getValueFromLastMessage(post.body)}
                          className={post.topicID?.topic == topic.topic ? "my-2 mr-12 text-gray": "my-2 mr-12 text-lightGray"}
                        >
                          reply
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="p-8 w-full  border-t-2 border-lightGray">
          <div className="w-full flex items-between justify-center  rounded-full flex-col">
            <div className="w-full flex items-center justify-between pr-4">
            <p className="text-xs mb-4">feel free to go in 💭</p>  {(timeRemaining == '-1s ') || (timeRemaining == null) ? <span className="text-green text-xs"> GO IN! </span> :  timeRemaining}
            </div>
            <form
              onSubmit={createPost}
              action=""
              className="h-10 flex flex-row justify-between border-2 border-gray w-full rounded-full bg-lightGray"
            >
              <span
                className={
                  loggedIn
                    ? "w-1/5 rounded-l-full flex justify-center items-center bg-lightGray"
                    : "bg-lightGray  w-1/5 rounded-l-full flex justify-center items-center"
                }
              >
                <p className="text-xs"> {prevMessage} </p>
              </span>
              <textarea
                ref={textInput}
                disabled={!loggedIn}
                name="post"
                placeholder={!loggedIn ? "please sign in" : "keep it goin...*"}
                onChange={(event) => setBody(event.target.value)}
                value={body}
                type="text"
                style={{ marginLeft: "-8px" }}
                className="px-2 pt-1 w-60 bg-lightGray"
                maxLength="150"
              />
              <button
                style={{ fontSize: 8, marginLeft: "-8px" }}
                disabled={!loggedIn}
                className={
                  !loggedIn
                    ? "p-2  bg-gray text-white rounded-r-2xl border-r border-gray"
                    : "p-2 bg-green text-white rounded-r-2xl "
                }
                type="submit"
              >
                GO' N!
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    </div>
    </>
  );
}

export default App;
